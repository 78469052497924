import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import PortableText from "../components/portableText";

import SEO from "../components/seo";
import Container from "../components/container";
import Omtale from "../components/omtale";
import Layout from "../components/layout";
import "../styles/layout.scss";

export const query = graphql`
  query testimonialsQuery {
    allSanityTestimonial {
      edges {
        node {
          name
          _rawBody
          _id
          image {
            alt
            asset {
              _id
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
        }
      }
    }
    omtaleSideText: allSanityOmtaleSide {
      edges {
        node {
          title
          _rawBody
          _id
        }
      }
    }
  }
`;

const TestimonialPage = () => {
  const data = useStaticQuery(query);
  const omtaler = mapEdgesToNodes(data.allSanityTestimonial);
  const omtaleSideText = mapEdgesToNodes(data.omtaleSideText);

  return (
    <Layout>
      <SEO title="Omtaler" />
      <Container>
        <div className="omtaler">
          <h1 className="omtaler__title">
            {omtaleSideText && omtaleSideText[0].title ? omtaleSideText[0].title : "Omtaler"}
          </h1>
          {omtaleSideText && omtaleSideText[0]._rawBody && (
            <div className="elevator__extra-info">
              <PortableText blocks={omtaleSideText[0]._rawBody} />
            </div>
          )}
          <div className="omtaler__wrapper">
            {omtaler.map(omtale => (
              <Omtale key={omtale._id} item={omtale} />
            ))}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default TestimonialPage;
