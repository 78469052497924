import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";

const omtale = ({ item }) => {
  const { image, name, _rawBody } = item;
  const itemId = item.name.split(" ");
  return (
    <div className="omtale" id={itemId[0]}>
      {image && image.asset && (
        <img
          className="omtale__image"
          src={imageUrlFor(buildImageObj(image))
            .width(50)
            .height(50)
            .fit("crop")
            .auto("format")
            .url()}
          alt={image.alt}
        />
      )}

      <h3>{name}</h3>
      {_rawBody && <PortableText blocks={_rawBody} />}
    </div>
  );
};

export default omtale;
